<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <collection-image-create :collection_id="id"></collection-image-create>
    <b-button block variant="primary" @click="callDone">Done</b-button>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import CollectionImageCreate from './CollectionImageCreate.vue'

export default {
  components: {
    VuexBreadcrumb,
    CollectionImageCreate
  },
  props: {
    collection_id: String
  },
  methods: {
    callDone() {
      this.$router.go(-1)
    }
  }
}
</script>